<template>
  <div class="mobile-heading" :style="backgroundImg">
    <div class="mobile-heading__background-color" :style="backgroundColor">
      <div class="mobile-heading__background-color" :style="backgroundGradient">
        <v-container>
          <v-row no-gutters>
            <v-col cols="6" offset="3" class="mobile-heading__item">
              <v-img class="logo shrink" contain :src="getLogoSRC" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="mobile-heading__item">
              <span
                ><strong>{{ trans("services_title") }}</strong></span
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="mobile-heading__item">
              <v-text-field
                :label="trans('services_input_placeholder')"
                prepend-inner-icon="$magnify"
                solo
                hide-details
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";

export default {
  name: "MobileHeading",
  mixins: [mobilePreviewActions],
  computed: {
    backgroundImg() {
      if (!this.config("tenants_mobile_image_services")) return {};
      return {
        backgroundImage: `url(${
          this.config("image_base_url") +
          this.config("tenants_mobile_image_services").name
        })`,
      };
    },
    backgroundColor() {
      return {
        backgroundColor: this.$helpers.hexToRgba(
          this.config("tenants_mobile_color_primary"),
          0.3
        ),
      };
    },
    backgroundGradient() {
      return {
        background: "linear-gradient(rgba(255,255, 255, 0) 30%, #767676)",
      };
    },
    getLogoSRC() {
      if (!this.config("tenants_mobile_image_logo")) return;
      return (
        this.config("image_base_url") +
        this.config("tenants_mobile_image_logo").name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-heading {
  width: 100%;
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-heading__background-color {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span {
  width: 100%;
  text-align: center;
  color: white;
}

.mobile-heading__item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
</style>
