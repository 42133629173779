<template>
  <div
    class="mobile-app"
    :style="{
      borderTopLeftRadius: `${getPreviewConfiguration.radiusDisplay}rem`,
      borderTopRightRadius: `${getPreviewConfiguration.radiusDisplay}rem`,
    }"
  >
    <div class="mobile-services-view">
      <mobile-heading />
      <template v-for="(category, index) in getServices">
        <mobile-subheading :key="index" :title="category.name" />
        <template v-for="(service, serviceIndex) in category.services">
          <mobile-service-card
            :key="`${category.id}-service-${serviceIndex}`"
            :service="service"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileHeading from "../components/MobileHeading.vue";
import MobileSubheading from "../components/MobileSubheading.vue";
import MobileServiceCard from "../components/MobileServiceCard.vue";

export default {
  name: "MobileHomeView",
  components: {
    MobileHeading,
    MobileSubheading,
    MobileServiceCard,
  },
  mixins: [mobilePreviewActions],
  computed: {
    ...mapGetters({
      getServices: "mobileEditor/getServices",
    }),
  },
};
</script>

<style lang="scss" scoped>
.mobile-app {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mobile-services-view {
  height: 100%;
  width: 100%;
}
</style>
