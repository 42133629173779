<template>
  <div class="mobile-service-card">
    <div class="mobile-service-card__title">
      <strong>{{ service.name }}</strong>
    </div>
    <div class="mobile-service-card__description text-truncate">
      <span class="description">{{ service.description }}</span>
    </div>
    <div class="mobile-service-card__details">
      <div class="mobile-service-card__details--box">
        <span class="cost">{{ service.price | money }}</span>
        <span class="duration">{{ service.duration | duration }}</span>
      </div>
      <mobile-button background="#78c800" :text="trans('services_select')" />
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileButton from "./MobileButton.vue";

export default {
  name: "MobileServiceCard",
  components: { MobileButton },
  mixins: [mobilePreviewActions],
  props: {
    service: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-service-card {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(4, auto);
  font-size: 10px;
  border-bottom: 1px solid #dadada;
  padding: 10px;
}

.mobile-service-card__title {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
}

.mobile-service-card__description {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.mobile-service-card__details {
  grid-row: 1 / 3;
  grid-column: 3 / 5;
  display: flex;
  justify-content: flex-end;
}

.mobile-service-card__details--box {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.duration {
  color: #a7aeb1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.description {
  color: #5f5f5f;
}
</style>
